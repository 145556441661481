import Moment from "moment";
import emitter from "./../services/emitter.js";
Moment.locale("pt-br");

class HelperService {
  callMakeToast(msg, type) {
    emitter.emit("makeToast", {
      msg,
      type,
    });
  }

  makeModalDefault(errorMessage, type) {
    this.$swal({
      type: type,
      icon: type,
      position: "top-center",
      html: errorMessage,
      showConfirmButton: false,
      timer: 2100,
    });
  }

  callMakeModalWithBtnsAndTitle(title, text, type) {
    emitter.emit("makeModalWithBtnsAndTitle", {
      title,
      text,
      type,
    });
  }

  maskCnpj(cnpj) {
    if (cnpj.length == 14) {
      return cnpj.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        "$1.$2.$3/$4-$5"
      );
    }

    return cnpj;
  }

  formatDate(date) {
    const dateFormat = new Date(date);
    return Moment(dateFormat).format("lll");
  }

  formatSimpleDate(date) {
    const dateFormat = new Date(date);
    return Moment(dateFormat).format("L");
  }

  getYearFromDate(date) {
    const dateFormat = new Date(date);
    return Moment(dateFormat).format("YYYY");
  }

  formatMoneyPtBR(number) {
    number = number.toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });

    return number;
  }
}

export default new HelperService();
